<template>
    <div class="city">
        <div class="top flex-wrap">
            <div class="name">热门城市</div>
            <ul class="list flex">
                <li v-for="(n,i) in list" :key="i">
                    <a href="javascript:void(0);" @click="hotTab(n,i)">{{n.itemText}}</a>
                </li>
            </ul>
        </div>
        <div class="bottom">
            <div class="title">常用城市</div>
            <div class="list flex" v-for="(n,i) in cityList" :key="i" v-if="n.length > 0">
                <div class="name" :id="i"><span>{{i}}</span></div>
                <ul class="child flex-wrap">
                    <li v-for="(k,s) in n" :key="s">
                        <a href="javascript:void(0);" @click="childTab(k)">{{k.itemText}}</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "allCity",
        data() {
            return {
                path:'/',
                list: [],
                cityList:[],
            }
        },
        methods: {
            hotCity(){
                this.$api.hotCityApi().then(res =>{
                    this.list = res.data.data;
                })
            },
            city() {
                let that = this;
                this.$api.cityAllApi().then(res => {
                    this.cityList = res.data.data;
                    this.hotCity();
                })
            },
            //热门城市筛选
            hotTab(n,i){
              n.number = i;
              let text = JSON.stringify(n);
              localStorage.setItem('hotLocation',text);
              localStorage.removeItem('location');
              this.$router.go(-1);
            },
            //常用城市筛选
            childTab(info){
                let text = JSON.stringify(info);
                localStorage.setItem('location',text);
                localStorage.removeItem('hotLocation');
                this.$router.go(-1);
            },
        },
        mounted(){
            this.city();
        }
    }
</script>

<style scoped lang="less">
    .city{
        width: 1200px;
        margin: 20px auto 0 auto;
        padding: 60px 0;
        background: white;
        .top{
            padding: 0 60px;
            .name{
                flex: 1;
                font-size: 20px;
                font-weight: bold;
            }
            .list{
                flex: 9;
                li{
                    margin-right: 10px;
                    padding: 1.5px 15px;
                    cursor: pointer;

                    a{
                        display: block;
                        width: 100%;
                        height: 100%;
                        color: #666666;
                    }
                }
                li:hover{
                    border-radius: 5px;
                    background: #00924c;
                    a{
                        color: white;
                    }
                }
            }
        }
        .bottom{
            .title{
                padding: 60px 60px 30px 60px;
                font-size: 20px;
                font-weight: bold;
            }
            .list{
                padding: 30px 60px;
                .name{
                    flex: 1;
                    span{
                        display: block;
                        width: 32px;
                        height: 32px;
                        line-height: 32px;
                        font-size: 18px;
                        font-weight: 700;
                        text-align: center;
                        color: #666666;
                        background-color: #fafafa;
                    }
                }
                .child{
                    flex: 9;
                    li{
                        margin-right: 10px;
                        margin-bottom: 10px;
                        padding: 1.5px 15px;
                        cursor: pointer;

                        a{
                            display: block;
                            width: 100%;
                            height: 100%;
                            color: #666666;
                        }
                    }
                    li:hover{
                        border-radius: 5px;
                        background: #00924c;
                        a{
                            color: white;
                        }
                    }
                }
            }
            .list:hover{
                background: #EDEDED;
                .name{
                    span{
                        color: white;
                        background: #cccccc;
                    }
                }
            }
        }
    }
</style>
